import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { AnimatePresence, motion } from "framer-motion"
import parse from "html-react-parser"

const Section = styled.section`
  ${tw`relative py-c120 md:py-c96 2xl:py-c120 z-30`}
`
const Teasers = styled.div`
  ${tw`relative grid sm:grid-cols-2 lg:grid-cols-4 gap-0`}
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 font-ubuntu font-black pb-c96 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const SingleColumn = styled(motion.div)`
  ${tw`relative cursor-pointer`}
`
const TeaserBox = styled(motion.div)`
  ${tw`relative pt-c25 pb-c25`}
  cursor: ${props => (props.accordion ? "pointer" : "default")};
`
const TeaserTitle = styled(motion.p)`
  ${tw`relative font-ubuntu uppercase font-bold text-20 md:text-24 xl:text-30 pb-c15 px-offset text-center h-c120 flex items-center justify-center my-c60`}
`
const TeaserDescriptionWrap = styled(motion.div)`
  ${tw`relative `}
  a {
    ${tw`underline`}
  }
`
const TeaserDescription = styled(motion.p)`
  ${tw`relative font-firacode font-normal text-center text-14 md:text-16 px-offset transition-all duration-500 ease-in-out`}
`
const Arrow = styled(motion.span)`
  ${tw`absolute block bottom-2 right-2 font-firacode font-bold text-24 transform`}
`

const WpAcfSmallCardsModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfSmallCardsBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""

  const [expanded, setExpanded] = useState([])
  const [hovered, setHovered] = useState([])

  return (
    <Section
      id={sectionId}
      style={{ backgroundColor: blockData.backgroundColor }}
    >
      <HeadlineWrap
        style={{
          color: blockData.headlineColor || "#000",
        }}
        className="headline-section font-ubuntu font-bold relative w-2/3"
      >
        <span
          className="relative"
          data-start-symbol={blockData.startSymbol}
          data-end-symbol={blockData.endSymbol}
        >
          {parse(blockData.headline)}
        </span>
      </HeadlineWrap>
      <SingleColumn >
        <Teasers>
          {blockData.cards.map((item, index) => {
            return (
              <TeaserItem
                key={`smc-${index}`}
                index={index}
                trimWords={15}
                title={item.cardHeadline}
                headlineColor={item.headlineColor}
                cardBackground={item.cardBackground}
                description={item.cardDescription}
                descriptionColor={item.descriptionColor}
                expanded={expanded}
                setExpanded={setExpanded}
                hovered={hovered}
                setHovered={setHovered}
                accordion={!!blockData.accordion}
              />
            )
          })}
        </Teasers>
      </SingleColumn>
    </Section>
  )
}

const TeaserItem = ({
  index,
  title,
  description,
  expanded,
  setExpanded,
  trimWords,
  cardBackground,
  headlineColor,
  descriptionColor,
  hovered,
  setHovered,
  accordion,
}) => {
  const transition = {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  }
  const arrowVariantsMain = {
    open: {
      display: "none",
      rotate: -90,
      transition: transition,
    },
    init: {
      rotate: -270,
      display: "block",
      transition: transition,
    },
    hover: {
      y: ["2px", "-2px"],
      transition: {
        type: "tween",
        yoyo: Infinity,
        duration: 0.3,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  }
  const arrowVariants = {
    open: {
      rotate: -90,
      transition: transition,
    },
    init: {
      rotate: -270,
      transition: transition,
    },
  }
  const teaserVariants = {
    open: {
      height: "auto",
      transition: transition,
    },
    init: {
      height: 0,
      transition: {
        type: "tween",
        duration: 0.4,
        ease: [0.04, 0.62, 0.23, 0.98],
      },
    },
  }

  const truncate = (string, words) => {
    return string.split(" ").splice(0, words).join(" ")
  }
  const truncateRest = (string, words) => {
    return " " + string.split(" ").splice(words, string.length).join(" ")
  }
  const getIndex = index => {
    if (index === 0) {
      return "z-40"
    }
    if (index === 1) {
      return "z-30"
    }
    if (index === 2) {
      return "sm:order-4 lg:order-none z-20"
    }
    if (index === 3) {
      return "sm:order-3 lg:order-none"
    }
    return "order-none z-10"
  }

  return (
    <TeaserBox
      style={{
        backgroundColor: cardBackground,
      }}
      accordion={accordion}
      className={getIndex(index)}
      initial={false}
      whileHover="hover"
      onHoverStart={() =>
        setHovered(currentHovered => {
          return [...currentHovered, index]
        })
      }
      onHoverEnd={() =>
        setHovered(currentHovered => {
          return currentHovered.filter(item => item !== index)
        })
      }
      onClick={() => {
        setExpanded(currentExpanded => {
          return expanded.includes(index)
            ? currentExpanded.filter(item => item !== index)
            : [...currentExpanded, index]
        })
      }}
      key={`textteaser-${index}`}
    >
      <TeaserTitle style={{ color: headlineColor }}>{title}</TeaserTitle>
      <AnimatePresence initial={false}>
        <TeaserDescriptionWrap key={`descwrap-${index}`}>
          <TeaserDescription
            style={{
              color: descriptionColor,
            }}
          >
            {accordion ? (
              <> 
                {truncate(description, trimWords)}
                {!expanded.includes(index) && <span>...</span>}
                {expanded.includes(index) && (
                  <motion.span
                    variants={teaserVariants}
                    initial="init"
                    animate="open"
                    exit="init"
                    style={{
                      backgroundColor: cardBackground,
                    }}
                    className="block left-0 right-0 absolute px-offset overflow-hidden pb-c20"
                  >
                    {parse(truncateRest(description, trimWords))}
                    <Arrow
                      style={{ color: headlineColor }}
                      initial="init"
                      animate={
                        expanded.includes(index)
                          ? "open"
                          : hovered.includes(index) && !expanded.includes(index)
                          ? "hover"
                          : "init"
                      }
                      exit="init"
                      variants={arrowVariants}
                    >{`->`}</Arrow>
                  </motion.span>
                )}
              </>
            ) : description}
          </TeaserDescription>
        </TeaserDescriptionWrap>
        {accordion && (
          <Arrow
            style={{ color: headlineColor }}
            initial="init"
            animate={
              expanded.includes(index)
                ? "open"
                : hovered.includes(index) && !expanded.includes(index)
                ? "hover"
                : "init"
            }
            exit="init"
            variants={arrowVariantsMain}
          >{`->`}</Arrow>
        )}
      </AnimatePresence>
    </TeaserBox>
  )
}

WpAcfSmallCardsModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfSmallCardsModuleBlock
